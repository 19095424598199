<template>
  <div id="inside">
    <div class="container">
      <div
          class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
              <button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-user-o"></i>cadastro</button
              ><button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-key"></i>código de acesso</button
              >
          </div>
        </div>
      <div class="row" :style="this.largura < 500 ? 'padding: 0 15px;' : ''">
        <div class="d-flex align-items-start justify-content-start flex-column pl-0 pb-4 col-lg-12">
          <div class="btn-group btn-group-sm ">
            <a href="/" class="btn btn-link d-flex flex-column justify-content-center"><i class="fa fa-home fa-fw fa-lg"></i></a>
             <router-link to="/dashboard"><a class="btn btn-link"></a></router-link>
          </div>
        </div>
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Cadastro</h4>
      </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-3">
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-8" :style="this.largura < 800 ? 'margin: 20px': ''">
          <div class="card">
            <div class="card-header" style=""><i class="fa fa-fw mr-2 fa-user text-info"></i>Meu cadastro</div>
            <div class="card-body pb-5" style="">
              <div class="row">
                <div class="col-md-12 col-12">
                </div>
              </div>
              <div class="row">
                 <div class="col-12 col-md-1">
                  </div>
                  <div class="col-12 col-md-10" style="">
                    <p class="m-0 text-info">Nome:</p>
                    <h6 class="">{{user.FullName}}</h6>
                    <p v-if="user.DocNumber.length < 14" class="m-0 text-info">CPF:</p>
                    <p v-if="user.DocNumber.length > 11" class="m-0 text-info">CNPJ:</p>
                    <h6 v-if="user.DocNumber.length < 14">{{ formatDocument(user.DocNumber).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX-' + formatDocument(user.DocNumber).split('-')[1] }}</h6>
                    <h6 v-if="user.DocNumber.length > 11">{{
              user.DocNumber.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              ).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX' + '/' + user.DocNumber.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              ).split('/')[1]
            }}</h6>
                    <p v-if="user.BirthDate" class="m-0 text-info">Data de nascimento:</p>
                    <h6>{{ user.BirthDate }}</h6>
                    <div class="row">
                      <div class="col-md-7" style="">
                        <p class="m-0 text-info" v-if="user.Locale != null || user.Number != null || user.Complement != null">Endereço:</p>
                        <p style="">{{user.Locale != null ? user.Locale + ',' : ''}} {{user.Number != null ? user.Number + ',' : ''}} {{user.Complement != null ? user.Complement + ',' : ''}}</p>
                      </div>
                      <div class="col-md-5" style="">
                        <p class="m-0 text-info" v-if="user.CEP">CEP</p>
                        <p style="">{{ user.CEP ? formatCep(user.CEP) : ''}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7" style="">
                        <p class="m-0 text-info" v-if="user.City">Cidade:</p>
                        <p style="">{{user.City || ''}}</p>
                      </div>

                      <div class="col-md-5" style="" v-if="user.State">
                        <p class="m-0 text-info">UF</p>
                        <p style="">{{user.State || ''}}</p>
                      </div>
                    </div>
                    <hr>
                    <h5 class="mb-3">Editar cadastro:</h5>
                      <a id="btn-cadastro" class="btn mr-1 mb-1 btn-outline-primary text-white" style="background-color: #4D2978; cursor: pointer;" @click="alterar" >Alterar dados</a>
                      <a id="btn-cadastro" class="btn mr-1 mb-1 btn-outline-primary text-white" @click="alterarSenha"  style="background-color: #4D2978; cursor: pointer;">Alterar código de acesso</a>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-1">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData ,formatDocument,formatCep} from "@/utils/helpers";
export default {
    name: "Leitura",

    components: {
     
    },
    data () {
        return {
          links: [

            ],
            loadUserInfo: false,
            userInfo: null,
            largura: null,
            contracts: [],
            agreements: [],
        }
    },
    async created() {
      this.largura = window.screen.width;
      // this.user.Menus.forEach(menuId => {
      //       switch(parseInt(menuId)){
      //           case 2:
      //               this.links.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
      //               break;
      //           case 1:
      //               this.links.push({ icon: '', text: 'Boletos Ativos', route: 'meusBoletos' });
      //               break;
      //           case 3:
      //               this.links.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
      //               break;
      //       }
      //   });
    },
    methods: {
        formatValue,
        formatDocument,
        formatData,
        formatCep,
        ...mapActions('cadastro',['ActionSetCurrentComponentRegister']),
        alterar() {
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');
        },
        alterarSenha() {
            this.ActionSetCurrentComponentRegister('alterarSenha');
        },
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
          });
        },

        contractsPending() {
          this.$router.push({
            name: "meusContratos",
          });
        },

        cadastro() {
          return;
        },
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
    }
};
</script>
<style scoped>

.btn-outline-primary:hover {
    background-color: #3f1e64 !important;
  }

</style>