<template>
  <div id="inside">
    <div class="container mb-5">
      <div
          class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
              <button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-user-o"></i>cadastro</button
              ><button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-key"></i>senha</button
              >
          </div>
        </div>
      <!-- <div class="row" :style="this.largura < 500 ? 'padding: 0 15px;' : ''">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Alterar senha</h4>
        </div>
      </div> -->
      <div class="row" :style="this.largura < 500 ? 'padding: 0 15px;' : ''">
        <div class="d-flex align-items-start justify-content-start flex-column pl-0 pb-4 col-lg-12">
          <div class="btn-group btn-group-sm ">
            <a href="/" class="btn btn-link d-flex flex-column justify-content-center"><i class="fa fa-home fa-fw fa-lg"></i></a>
             <router-link to="/dashboard"><a class="btn btn-link"></a></router-link>
          </div>
        </div>
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Alterar senha</h4>
      </div>
      </div>
    </div>
    <div class="container">
      <form  v-on:submit="changePassword" v-on:submit.prevent>
      <div class="row" style="">
        <!-- aqui-->
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-12">
          <div class="card" :style="this.largura < 500 ? 'margin: 20px 15px;' : ''">
            <div class="card-header" style="">
              <p class="m-0 text-info">Preencha os campos abaixo para alterar sua senha.</p>
            </div>
            <div class="card-body pb-2 pt-0">
              <div class="row">
                        <div class="col-md-7">
                          <div class="form-group">
                            <input 
                              v-model="form.password"
                              :disabled="loading"
                              @input="$v.form.password.$touch()"
                              @blur="$v.form.password.$touch()"
                              required
                              type="password" class="form-control my-3" placeholder="Código atual" name="Senha-atual"></div>

                            <div class="form-group">
                              <input 
                                v-model="form.newPassword"
                                :disabled="loading"
                                @input="$v.form.newPassword.$touch()"
                                @blur="$v.form.newPassword.$touch()"
                                :class='{valid:passwordValidation.valid}'
                                required
                                v-model.lazy='form.newPassword'
                                type="password" class="form-control my-3" placeholder="Novo código" name="nova-senha"></div>

                              <div class="form-group">
                                <input 
                                  v-model="form.confirmPassword"
                                  :disabled="loading"
                                  @input="$v.form.confirmPassword.$touch()"
                                  @blur="$v.form.confirmPassword.$touch()"
                                  required
                                  type="password" class="form-control my-3" placeholder="Repetir novo código" name="repetir-nova-senha"></div>
                        </div>

                        <div class="col-12 col-md-5" style="">
                          <transition name="hint" appear>
                            <div v-if='passwordValidation.errors.length > 0' class='hints'>
                              <p class="btn-link m-0 small " style="color: #4D2978;">O novo código deve conter:</p>
                              <p class="m-0 small" v-for='error in passwordValidation.errors' v-bind:key="error">{{error}}</p>
                            </div>
                          </transition>
                        </div>
                      </div>
                      <div v-if="docNumberErrors.length" style="text-align:left;font-size:11px;">
                        <b v-for="error in docNumberErrors" v-bind:key="error" v-bind:class="classMessage" class="form-text">{{ error }}</b>
                      </div>
                      <div class="row" v-if="loading">
                            <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                                <ProgressLoading v-bind:loading="loading"/>
                            </div>
                        </div>
                        <!-- <a class="btn mr-1 mb-1 btn-outline-primary" href="#" style="">Confirmar</a> -->
                        <button v-if="!loading" @click="voltarFormulario" type="buton" style="margin-top:10px;" class="btn mr-1 mb-1 btn-outline-secondary-grey">Voltar</button>
                        <button v-if="!loading" type="submit" style="margin-top:10px" class="btn mr-1 mb-1 btn-outline-primary btn-orange" >Confirmar</button>
            </div>
          </div>
        </div>
        
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from '../../../../modules/auth/components/ProgressLoading.vue';
export default {
    name: "AlterarSenha",
    mixins: [validationMixin],
    validations: {
        form: {
            password: {required, minLength: minLength(6)},
            newPassword: {required, minLength: minLength(6)},
            confirmPassword: {required, minLength: minLength(6)}
        }
    },
    data: () => ({
        links:[],
        form : {
            password: null,
            newPassword: null,
            confirmPassword:null
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        rules: [
          { message:'Pelo menos uma letra minúscula', regex:/[a-z]+/ },
          { message:"Pelo menos uma letra maiúscula.",  regex:/[A-Z]+/ },
          { message:"Mínimo de 8 caractes.", regex:/.{8,}/ },
          { message:"Pelo menos um número.", regex:/[0-9]+/ }
        ],
        errors: false,
        loading: false,
        largura: null,
        contracts: [],
        agreements: [],
        userAdm: {},
        ipUser: null
    }),
    async created(){
      this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
      this.ipUser = localStorage.getItem('ipUser');
      this.largura = window.screen.width;
    },
    components: {
      ProgressLoading
    },
    methods: {
        ...mapActions('cadastro',['ActionSetCurrentComponentRegister','ActionUpdateDebtorPassword']),
        voltarFormulario() {
            this.ActionSetCurrentComponentRegister('cadastroLeitura');
        },
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
        limparFormulario: function () {
            this.form.password = '';
            this.form.newPassword = '';
            this.form.confirmPassword = '';
        },
        async changePassword(){
           this.loading = true;
           this.$v.$touch();
           this.docNumberErrors.pop();
          
            if(!this.passwordValidation.valid){
              this.loading = false;
              this.docNumberErrors.push('nova senha não atende aos requisitos mínimos');
              return;
            }

           if (this.docNumberErrors.length > 0) {
              this.loading = false;
              return;
           }

            try {
                let response = await this.ActionUpdateDebtorPassword({
                  PromoCode:this.form.newPassword,
                  PromoCodeOld:this.form.password,
                  UserID: this.userAdm ? this.userAdm.UserID : null,
                  UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
                  OS: navigator.platform,
                  IP: this.ipUser ? this.ipUser : '',
                });
                 this.loading = false;

                if(response.status == 401){
                  this.docNumberErrors.push('As credênciais fornecidas estão incorretas, verifique sua senha e tente novamente.');
                  this.error.status = 'ERROR';
                  return;
                }

                //  this.error.status = 'SUCCESS';
                 this.limparFormulario();
                 this.flashMessage.show({
                  timeout: 1000,
                  status: "success",
                  title: "Sucesso",
                  blockClass: "custom-block-class",
                  message: "Seu código de acesso foi alterado com sucesso!",
                });
                //  this.docNumberErrors.push('Seu código de acesso foi alterado com sucesso!');
            } catch (error) {
                console.log(error);
               this.loading = false;
               this.docNumberErrors.push('Ocorreu um erro ao tentar atualizar seu código de acesso, tente novamente mais tarde.');
               this.error.status = 'ERROR';
            }
        },

        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
          });
        },

        contractsPending() {
          this.$router.push({
            name: "meusContratos",
          });
        },

        cadastro() {
          return;
        },
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
        docNumberErrors () {
            const errors = [];
            if(!this.$v.form.password.$dirty) return errors;

            if(!this.$v.form.newPassword.$dirty) return errors;

            if(!this.$v.form.confirmPassword.$dirty) return errors;
            this.form.confirmPassword !=  this.form.newPassword && errors.push('nova senha e confirmação não conferem');

            return errors;
        },
        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-info': this.error.status == "SUCCESS", 
                'text-muted': !this.error.status == "ERROR"
            }
        },
       
        passwordValidation () {
          let errors = []
          for (let condition of this.rules) {
            if (!condition.regex.test(this.form.newPassword)) {
              errors.push(condition.message)
            }
          }
          if (errors.length === 0) {
            return { valid:true, errors }
          } else {
            return { valid:false, errors }
          }
        }
    }
};
</script>